import React, { useEffect } from 'react'

export default function NotFound() {
    useEffect(() => {
        window.location.replace("https://lister.arrozchispa.com/login/")
    }, []);
  return (
    <div></div>
  )
}
//window.location.replace("http://localhost:8000/login/")
//window.location.replace("https://lister.arrozchispa.com/login/")