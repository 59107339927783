import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function NewArrival() {
    const [producers, setProducers] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [error, setError] = useState("");
    const [fillList, setFillList] = useState({});
    const [lister1, setLister1] = useState([]);
    const [lister2, setLister2] = useState([]);
    const [tanks, setTanks] = useState([]);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const setField = (field, value) => {
        setForm({
        ...form, 
        [field]:value
        });
    };

    useEffect(() => {
        axios.get('productor_select/').then((response) => {
            setProducers(response.data)
        });

        axios.get('tanque_select/').then((response) => {
            setTanks(response.data);
            setLister1(response.data.filter((x) => x.lister.nombre === 'Lister 1'));
            setLister2(response.data.filter((x) => x.lister.nombre === 'Lister 2'));
        });
    }, []);

    const getTrucks = (event) => {
        setForm({
            ...form,
            productor_id: event.target.value,
            camion_id: null,
        });
        setTrucks([]);
        axios.post('get_productorcamion/', {
            productor_id: event.target.value
        }).then((response) => {
            setTrucks(response.data)
        });
    };

    const back = () => {
        if(page === 2 ) {
            setPage(1);
            return;
        }

        navigate('/arrival');
    }

    const submit = () => {
        if(!form.hora_recepcion)
        {
            setError("El campo Fecha y Hora es obligatorio");
            return;
        }

        if(page === 1) {
            setPage(2);
            return;
        }

        axios.post('recepcion/', form).then((response) => {
            setError("")
            window.location.replace('/arrival')
        }).catch((err) => {
            setError("Por favor revise todos los campos");
        })
    }

    const getKGLeft = () => {
        let kg = parseInt(form.kg_estimados);
        if(isNaN(kg)) return 0;
        if(Object.keys(fillList).length > 0)
        {
            for (const key in fillList) {
                if (fillList.hasOwnProperty(key)) {
                    const value = fillList[key];
                    if(!isNaN(parseInt(value))) kg -= parseInt(value);
                }
            }
        }
        return kg;
    }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content} pt-5`}>
            <div className={'mt-4'}>
                <h1>Nueva recepción</h1>
            </div>

            {page === 1 ?
                <div className={cruds.input_container}>
                    <div className={cruds.containerPart}>
                        <div className={`${cruds.inputs}`}>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Fecha y Hora Recepción</label>
                                <input type="datetime-local" className={cruds.text_input} onChange={x => setField("hora_recepcion", x.target.value)}></input>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Productor</label>
                                <select onChange={getTrucks} className={cruds.select_input}>
                                    <option value="">-- Seleccione una opción --</option>
                                    {producers.map((productor, index) => (
                                        <option key={index} value={productor.id}>{productor.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Camión</label>
                                <select className={cruds.select_input} onChange={x => setField("camion_id", x.target.value)}>
                                    <option value="">-- Seleccione una opción --</option>
                                    {trucks.map((truck, index) => (
                                        <option key={index} value={truck.id}>{truck.identificador}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Kilogramos Estimados</label>
                                <input type="number" className={cruds.text_input} onChange={x => setField("kg_estimados", x.target.value)}></input>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Humedad</label>
                                <input type="number" className={cruds.text_input} onChange={x => setField("humedad_recepcion", x.target.value)}></input>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Temperatura</label>
                                <input type="number" className={cruds.text_input} onChange={x => setField("temperatura_recepcion", x.target.value)}></input>
                            </div>
                            <div className={`${cruds.input_container} pb-4`}>
                                <label className={cruds.text_input_label}>Impureza</label>
                                <input type="number" className={cruds.text_input} onChange={x => setField("impureza_recepcion", x.target.value)}></input>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={cruds.input_container}>
                    <div className={cruds.containerPart}>
                        <div className={`${cruds.inputs}`} style={{paddingLeft: '140px'}}>
                            <h3>KG Restantes: {getKGLeft()}</h3>
                            <div className={`${cruds.oneToManyBox} pb-4 mt-4 w-100`}>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'d-flex'}>
                                        <div>
                                            {lister1.slice(0, 12).map((tank, index) => {
                                                const kgLeft = tank.capacidad_maxima - tank.kg_estimados - (form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);
                                                const myKG = parseInt(tank.kg_estimados) + parseInt(form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);

                                                return (
                                                    <div className={'d-flex align-items-center'}>
                                                        <input className={'me-2'} style={{fontSize: 'large', width: '100px'}} type='number' value={form[`tank_${tank.id}`]} onChange={(event) => {
                                                            let currKg = form[`tank_${tank.id}`];
                                                            currKg = event.target.value - currKg;
                                                            if(getKGLeft() - currKg < 0) {
                                                                return;
                                                            }
                                                            if(tank.capacidad_maxima - tank.kg_estimados - event.target.value < 0) return;
                                                            setField(`tank_${tank.id}`, event.target.value);
                                                            setFillList({...fillList, [tank.id]: event.target.value});
                                                        }}/>
                                                        <div className={cruds.tankBox} style={{
                                                            backgroundColor: `${myKG >= 15000 ? 'red' : myKG > 1000 ? 'orange' : 'green'}`,
                                                        }}>
                                                            <div>{tank.nombre}</div>
                                                            <div>({myKG}kg)</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {lister1.slice(24,25).map((tank, index) => {
                                                const kgLeft = tank.capacidad_maxima - tank.kg_estimados - (form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);
                                                const myKG = parseInt(tank.kg_estimados) + parseInt(form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);

                                                return (
                                                    <div className={'d-flex align-items-center'}>
                                                        <input className={'me-2'} style={{fontSize: 'large', width: '100px'}} type='number' value={form[`tank_${tank.id}`]} onChange={(event) => {
                                                            let currKg = form[`tank_${tank.id}`];
                                                            currKg = event.target.value - currKg;
                                                            if(getKGLeft() - currKg < 0) {
                                                                return;
                                                            }
                                                            if(tank.capacidad_maxima - tank.kg_estimados - event.target.value < 0) return;
                                                            setField(`tank_${tank.id}`, event.target.value);
                                                            setFillList({...fillList, [tank.id]: event.target.value});
                                                        }}/>
                                                        <div className={cruds.tankBox} style={{
                                                            backgroundColor: `${myKG >= 15000 ? 'red' : myKG > 1000 ? 'orange' : 'green'}`,
                                                        }}>
                                                            <div>{tank.nombre}</div>
                                                            <div>({myKG}kg)</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div>
                                            {lister1.slice(12, 24).reverse().map((tank, index) => {
                                                const kgLeft = tank.capacidad_maxima - tank.kg_estimados - (form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);
                                                const myKG = parseInt(tank.kg_estimados) + parseInt(form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);

                                                return (
                                                    <div className={'d-flex align-items-center'}>
                                                        <div className={cruds.tankBox} style={{
                                                            backgroundColor: `${myKG >= 15000 ? 'red' : myKG > 1000 ? 'orange' : 'green'}`,
                                                        }}>
                                                            <div>{tank.nombre}</div>
                                                            <div>({myKG}kg)</div>
                                                        </div>
                                                        <input className={'me-2'} style={{fontSize: 'large', width: '100px'}} type='number' value={form[`tank_${tank.id}`]} onChange={(event) => {
                                                            let currKg = form[`tank_${tank.id}`];
                                                            currKg = event.target.value - currKg;
                                                            if(getKGLeft() - currKg < 0) {
                                                                return;
                                                            }
                                                            if(tank.capacidad_maxima - tank.kg_estimados - event.target.value < 0) return;
                                                            setField(`tank_${tank.id}`, event.target.value);
                                                            setFillList({...fillList, [tank.id]: event.target.value});
                                                        }}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className={'d-flex'}>
                                        <div>
                                            {lister2.slice(0, 11).map((tank, index) => {
                                                const kgLeft = tank.capacidad_maxima - tank.kg_estimados - (form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);
                                                const myKG = parseInt(tank.kg_estimados) + parseInt(form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);

                                                return (
                                                    <div className={'d-flex align-items-center'}>
                                                        <input className={'me-2'} style={{fontSize: 'large', width: '100px'}} type='number' value={form[`tank_${tank.id}`]} onChange={(event) => {
                                                            let currKg = form[`tank_${tank.id}`];
                                                            currKg = event.target.value - currKg;
                                                            if(getKGLeft() - currKg < 0) {
                                                                return;
                                                            }
                                                            if(tank.capacidad_maxima - tank.kg_estimados - event.target.value < 0) return;
                                                            setField(`tank_${tank.id}`, event.target.value);
                                                            setFillList({...fillList, [tank.id]: event.target.value});
                                                        }}/>
                                                        <div className={cruds.tankBox} style={{
                                                            backgroundColor: `${myKG >= 15000 ? 'red' : myKG > 1000 ? 'orange' : 'green'}`,
                                                        }}>
                                                            <div>{tank.nombre}</div>
                                                            <div>({myKG}kg)</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div>
                                            {lister2.slice(11, 22).reverse().map((tank, index) => {
                                                const kgLeft = tank.capacidad_maxima - tank.kg_estimados - (form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);
                                                const myKG = parseInt(tank.kg_estimados) + parseInt(form[`tank_${tank.id}`] ? form[`tank_${tank.id}`] : 0);

                                                return (
                                                    <div className={'d-flex align-items-center'}>
                                                        <div className={cruds.tankBox} style={{
                                                            backgroundColor: `${myKG >= 15000 ? 'red' : myKG > 1000 ? 'orange' : 'green'}`,
                                                        }}>
                                                            <div>{tank.nombre}</div>
                                                            <div>({myKG}kg)</div>
                                                        </div>
                                                        <input className={'me-2'} style={{fontSize: 'large', width: '100px'}} type='number' value={form[`tank_${tank.id}`]} onChange={(event) => {
                                                            let currKg = form[`tank_${tank.id}`];
                                                            currKg = event.target.value - currKg;
                                                            if(getKGLeft() - currKg < 0) {
                                                                return;
                                                            }
                                                            if(tank.capacidad_maxima - tank.kg_estimados - event.target.value < 0) return;
                                                            setField(`tank_${tank.id}`, event.target.value);
                                                            setFillList({...fillList, [tank.id]: event.target.value});
                                                        }}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div>
                <div className={`${cruds.buttons_in_row}`} style={{paddingTop: '25rem'}}>
                    <div className={`${cruds.save_button}`} onClick={back}>
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </div>
                    <div className={`${cruds.save_button}`}>
                        <Button onClick={submit}>
                            <span>Guardar</span>
                        </Button>
                    </div>
                </div>
                <div className={`${cruds.error_span} pt-3`}>
                    <span>{error}</span>
                </div>
            </div>
        </div>
    </div>
  )
}