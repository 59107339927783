import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'


export default function CreateTruckScreen() {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const submit = () => {
    axios.post('camion/', {
      identificador: name,
    }).then((response) => {
      setError("")
      window.location.replace('/truck')
    }).catch((err) => {
      setError("Por favor revise todos los campos");
    })
  }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content} pt-5`}>
            <h1>Nuevo camión</h1>

            <div className={`${cruds.inputs}`}>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Identificador del camión</label>
                    <input className={cruds.text_input} onChange={x => setName(x.target.value)}></input>
                </div>

                <div className={`${cruds.buttons_in_row}`}>
                  <Link className={`${cruds.save_button}`} to="/truck">
                    <Button>
                      <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                      <span>Regresar</span>
                    </Button>
                  </Link>
                  <div className={`${cruds.save_button}`}>
                    <Button onClick={submit}>
                      <span>Guardar</span>
                    </Button>
                  </div>
                </div>
                <div className={`${cruds.error_span} pt-3`}>
                    <span>{error}</span>
                </div>
            </div>
        </div>
    </div>
  )
}
