import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/HeaderComponent'
import SidebarComponent from '../components/SidebarComponent'
import styles from "../styles/Tank.module.css"
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from '../AxiosInstance'
import LoadingComponent from '../components/LoadingComponent'
import { Link } from 'react-router-dom';
import PaginationComponent from '../components/PaginationComponent'

export default function TankScreen() {
    const [tanks, setTanks] = useState([])
    const [loaded, setLoaded] = useState(false);
    const [listers, setListers] = useState([]);

    const [activeFilter, setActiveFilter] = useState();
    const [listerFilter, setListerFilter] = useState();

    useEffect(() => {
        axios.get('tanque/').then((response) => {
            setTanks(response.data.results);
            setLoaded(true);
        });

        axios.get('lister_select/').then((response) => {
            setListers(response.data);
        });
    }, []);

    const search = () => {
        let url = "tanque/?";
        if(activeFilter !== "") url += '&activo=' + activeFilter;
        if(listerFilter !== undefined) url += '&lister_nombre=' + listerFilter;
        axios.get(url).then((response) => {
            setTanks(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setTanks(data);
    }

    const readableTime = (data) => {
        if(data == null) return "-";
        const date = new Date(data);
        return date.toLocaleString();
    }

    const dryTime = (data) => {
        if (data == null) return '-';
        const now = new Date();
        const date = new Date(data);
        console.log(now);
        console.log(data);
        
        const diffMs = Math.abs(now - date);

        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
        const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

        return `${diffHours}:${diffMinutes}:${diffSeconds}`;
    }
  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${styles.content} pt-4`}>
            <h1>Lister Tanques Actuales</h1>

            <div className={`${styles.inner_container} pt-2`}>

                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Activo</label>
                        <select className={styles.select_input} onChange={x => setActiveFilter(x.target.value)}>
                            <option value="">-- Seleccione una opción --</option>
                            <option value={"true"}>Si</option>
                            <option value={"false"}>No</option>
                        </select>
                    </div>

                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Lister</label>
                        <select className={styles.select_input} onChange={x => setListerFilter(x.target.value)}>
                            <option value="">-- Seleccione una opción --</option>
                            {listers.map((lister, index) => (
                                <option value={lister.id}>{lister.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>

                <table>
                    <tr>
                        <th/>
                        <th>Tanque/Lister</th>
                        <th>Productor/Camión</th>
                        <th>KG estimados</th>
                        <th>Humedad Rec.</th>
                        <th>Temperatura Rec.</th>
                        <th>Impureza</th>
                        <th>Ult. Actualización</th>
                        <th>Tiempo de Secado</th>
                        <th>Ult Humedad</th>
                        <th>Observación</th>
                    </tr>
                    {tanks.map((tank, index) => (
                    <tr style={{ backgroundColor: tank.lister.id === 1 ? '#e1c5c5' : tank.lister.id === 2 ? '#c3d7c3' : tank.lister.id === 3 ? '#c8d4e3' : tank.lister.id === 4 ? '#e8d8a8' : '#d6c1e1' }}>
                        <th>{index + 1}</th>
                        <th>{tank.nombre}</th>
                        <th>{tank.ultimo_productor && tank.ultimo_productor.nombre}/{tank.ultimo_camion && tank.ultimo_camion.identificador}</th>
                        <th>{tank.kg_estimados?.toLocaleString()}</th>
                        <th>{tank.humedad_recepcion?.toFixed(1)}</th>
                        <th>{tank.temperatura_recepcion?.toFixed(1)}</th>
                        <th>{tank.impureza_recepcion?.toFixed(2)}</th>
                        <th>{readableTime(tank.ultima_actualizacion)}</th>
                        <th>{dryTime(tank.hora_recepcion)}</th>
                        <th>{tank.ultima_humedad?.toFixed(2)}</th>
                        <th>{tank.comentario}</th>
                    </tr>
                    ))}
                </table>

                <PaginationComponent prop={"tanque"} func={pullData} limit={100}/>

                <div className={`${styles.lower_bar}`}>
                    <Link className={`${styles.lower_button_container}`} to='/tank/fill/history'>
                        <Button>
                            <span>Historial de llenado</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to='/tank/update'>
                        <Button>
                            <span>Actualizar</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to='/tank/move'>
                        <Button>
                            <span>Trasladar</span>
                        </Button>
                    </Link>
                </div>

            </div>
        </div>}
    </div>
  )
}
