// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_content__HnpMW {\n    padding-left: 250px;\n    height: 40%;\n    width: calc(100% - 250px);\n    color: #9DD879;\n    flex-grow: 1;\n    right: 0;\n    z-index: -2 !important;\n}\n\n.Home_bold__SzG3t {\n    font-weight: 700;\n    color: #9DD879;\n}\n\n.Home_button__nG-AV {\n    border-radius: 8px;\n    border: #78B54A 1px solid;\n    font-weight: 500;\n    width: auto;\n    cursor: pointer !important;\n    padding: 10px;\n}\n\n.Home_button__nG-AV:hover {\n    background-color: #78B54A;\n    color: whitesmoke;\n}\n\n", "",{"version":3,"sources":["webpack://./src/styles/Home.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,QAAQ;IACR,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;IACX,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".content {\n    padding-left: 250px;\n    height: 40%;\n    width: calc(100% - 250px);\n    color: #9DD879;\n    flex-grow: 1;\n    right: 0;\n    z-index: -2 !important;\n}\n\n.bold {\n    font-weight: 700;\n    color: #9DD879;\n}\n\n.button {\n    border-radius: 8px;\n    border: #78B54A 1px solid;\n    font-weight: 500;\n    width: auto;\n    cursor: pointer !important;\n    padding: 10px;\n}\n\n.button:hover {\n    background-color: #78B54A;\n    color: whitesmoke;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Home_content__HnpMW",
	"bold": "Home_bold__SzG3t",
	"button": "Home_button__nG-AV"
};
export default ___CSS_LOADER_EXPORT___;
