// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__2t6Je {\n    background-color: #9DD879;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 100px;\n    box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.3);\n    position:sticky;\n    top: 0;\n    left: 0;\n    z-index: 10 !important;\n    align-content: center;\n    align-items: center;\n    flex-direction: row;\n}\n\n.Header_header__2t6Je div:first-child {\n    padding-left: auto;\n    margin-left: 20px;\n}\n\n.Header_header__2t6Je div:last-child {\n    padding-right: auto;\n    margin-right: 10px;\n    display: flex;\n    align-items: flex-start;\n}\n\n.Header_header__2t6Je div:last-child div {\n    margin-left: 10px;\n    font-weight: 700;\n    font-size: larger;\n}", "",{"version":3,"sources":["webpack://./src/styles/Header.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,0CAA0C;IAC1C,eAAe;IACf,MAAM;IACN,OAAO;IACP,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".header {\n    background-color: #9DD879;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 100px;\n    box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.3);\n    position:sticky;\n    top: 0;\n    left: 0;\n    z-index: 10 !important;\n    align-content: center;\n    align-items: center;\n    flex-direction: row;\n}\n\n.header div:first-child {\n    padding-left: auto;\n    margin-left: 20px;\n}\n\n.header div:last-child {\n    padding-right: auto;\n    margin-right: 10px;\n    display: flex;\n    align-items: flex-start;\n}\n\n.header div:last-child div {\n    margin-left: 10px;\n    font-weight: 700;\n    font-size: larger;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__2t6Je"
};
export default ___CSS_LOADER_EXPORT___;
