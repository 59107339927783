import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/HeaderComponent'
import LoadingComponent from '../components/LoadingComponent';
import SidebarComponent from '../components/SidebarComponent'
import axios from '../AxiosInstance'
import styles from '../styles/Paddy.module.css'
import PaginationComponent from '../components/PaginationComponent';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'

export default function TruckScreen() {
    const [loaded, setLoaded] = useState(false);
    const [trucks, setTrucks] = useState([]);

    const [nameFilter, setNameFilter] = useState();

    useEffect(() => {
        axios.get('camion/').then((response) => {
            setTrucks(response.data.results);
            setLoaded(true);
        })
    }, []);

    const search = () => {
        setLoaded(false);
        let url = "camion/?";
        if(nameFilter !== "") url += '&identificador=' + nameFilter;
        axios.get(url).then((response) => {
            setTrucks(response.data.results);
            setLoaded(true);
        })
    }

    const pullData = (data) => {
        setTrucks(data);
    }
  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        {loaded === false ? <LoadingComponent/> :
        <div className={`${styles.content}`}>
            <div className={`${styles.inner_container} pt-5`}>
                <h1>Camiones</h1>

                <div className={`${styles.filter_top}`}>
                    <div className={`${styles.text_input_container} pb-3`}>
                        <label className={styles.text_input_label}>Identificador</label>
                        <input className={styles.text_input} onChange={x => setNameFilter(x.target.value)}></input>
                    </div>
                    <div className={`${styles.button_container}`}>
                        <Button onClick={search}>
                            <FontAwesomeIcon icon={faSearch} color="black"/>
                        </Button>
                    </div>
                </div>

                <table>
                    <tr>
                        <th/>
                        <th>Identificador</th>
                    </tr>
                    {trucks.map((truck, index) => (
                    <tr key={truck.id}>
                        <th>{index + 1}</th>
                        <th>{truck.identificador}</th>
                    </tr>
                    ))}
                </table>
                <PaginationComponent prop={"camion"} func={pullData} limit={14}/>
                <div className={`${styles.lower_bar}`}>
                    <Link className={`${styles.lower_button_container}`} to="/arrival">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                    <Link className={`${styles.lower_button_container}`} to="/truck/create">
                        <Button>
                            <FontAwesomeIcon icon={faTruck} color="black"/>
                            <span>Nuevo Camión</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
        }
    </div>
  )
}
