import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function RealKG() {
    const [tanks, setTanks] = useState([]);
    const [error, setError] = useState("");
    const [form, setForm] = useState({});
    const setField = (field, value) => {
        setForm({
        ...form, 
        [field]:value
        });
    };

    useEffect(() => {
        axios.get('tanque_select/?activo=true').then((response) => {
            setTanks(response.data);
        });
    }, []);

    const submit = () => {
        axios.get('tanque/' + form.tanque_id + "/").then((response) => {
            const data = response.data;
            axios.put('tanque/' + form.tanque_id + "/", {
                kg_reales: form.kg_real,
                nombre: data.nombre,
                capacidad_maxima: data.capacidad_maxima
            }).then((response) => {
                setError("")
                window.location.replace('/tank')
            }).catch((err) => {
                setError("Por favor revise todos los campos");
            });
        })
    }
  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content} pt-5`}>
            <div className={`${cruds.inputs}`}>
                <h1 className='pb-4'>Kilogramos Reales</h1>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Tanque</label>
                    <select onChange={x => setField("tanque_id", x.target.value)} className={cruds.select_input}>
                        <option value="">-- Seleccione una opción --</option>
                        {tanks.map((tank, index) => (
                            <option key={index} value={tank.id}>{tank.nombre}</option>
                            ))}
                    </select>
                </div>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>KG Reales</label>
                    <input type="number" className={cruds.text_input} onChange={x => setField("kg_real", x.target.value)}></input>
                </div>

                <div className={`${cruds.buttons_in_row} pt-3`}>
                    <Link className={`${cruds.save_button}`} to="/tank">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                    <div className={`${cruds.save_button}`}>
                        <Button onClick={submit}>
                            <span>Guardar</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
