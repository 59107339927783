import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function MoveToSiloScreen() {
    const [silos, setSilos] = useState([]);
    const [tanks, setTanks] = useState([]);
    const [error, setError] = useState("");
    const [form, setForm] = useState({});

    const setField = (field, value) => {
        setForm({
        ...form, 
        [field]:value
        });
    };

    useEffect(() => {
        axios.get('tanque_select/').then((response) => {
            setTanks(response.data);
        });

        axios.get('silo_select/').then((response) => {
            setSilos(response.data);
        });
    }, []);

    const submit = () => {
        // Check if new total excedes tank capacity

        const myTank = tanks.filter(obj => obj.id === parseInt(form.tanque_id));
        const mySilo = silos.filter(obj => obj.id === parseInt(form.silo_id));

        const newTotal = parseInt(myTank[0].kg_estimados) + parseInt(mySilo[0].stock);

        if(newTotal <= mySilo[0].capacidad_maxima)
        {
            axios.post('translado/', {
                humedad: form.humedad,
                impureza: form.impureza,
                fecha_hora: form.fecha_hora,
                tanque_id: form.tanque_id,
                silo_id: form.silo_id
            }).then((response) => {
                setError("")
                window.location.replace('/tank')
            }).catch((err) => {
                setError("Por favor revise todos los campos");
            });
        }
        else
        {
            setError("El nuevo total excede la capacidad máxima del tanque");
        }
    }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content} pt-5`}>

            <div className={`${cruds.inputs}`}>
                <h1 className="pb-4">Traslado de tanques a silos</h1>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Fecha y Hora Traslado</label>
                    <input type="datetime-local" className={cruds.text_input} onChange={x => setField("fecha_hora", x.target.value)}></input>
                </div>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Tanque</label>
                    <select onChange={x => setField("tanque_id", x.target.value)} className={cruds.select_input}>
                        <option value="">-- Seleccione una opción --</option>
                        {tanks.map((tank, index) => (
                            <option key={index} value={tank.id}>{tank.nombre}</option>
                            ))}
                    </select>
                </div>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Humedad</label>
                    <input type="number" className={cruds.text_input} onChange={x => setField("humedad", x.target.value)}/>
                </div>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Impureza</label>
                    <input type="number" className={cruds.text_input} onChange={x => setField("impureza", x.target.value)}/>
                </div>

                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Silo</label>
                    <select onChange={x => setField("silo_id", x.target.value)} className={cruds.select_input}>
                        <option value="">-- Seleccione una opción --</option>
                        {silos.map((silo, index) => (
                            <option key={index} value={silo.id}>{silo.nombre}</option>
                            ))}
                    </select>
                </div>

                <div className={`${cruds.buttons_in_row}`}>
                    <Link className={`${cruds.save_button}`} to="/tank">
                        <Button>
                            <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                            <span>Regresar</span>
                        </Button>
                    </Link>
                    <div className={`${cruds.save_button}`}>
                        <Button onClick={submit}>
                            <span>Guardar</span>
                        </Button>
                    </div>

                    <Link className={`${cruds.save_button}`} to="/tank/move/history">
                        <Button>
                            <span>Historial</span>
                        </Button>
                    </Link>
                </div>
            </div>

            <div className={`${cruds.error_span} pt-3`}>
                <span>{error}</span>
            </div>
        </div>
    </div>
  )
}
