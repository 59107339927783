import React, { useState } from 'react'
import styles from '../styles/Login.module.css';
import { Row, Button } from 'react-bootstrap';
import axios from '../AxiosInstance'

export default function LoginScreen() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const login = () => {
        axios.get('api/get_usuario/', {
            listersessionid: "y7k7fv7zrh6opoeyjbcj5c4z06z4h0e9"
        });
    }

    const forgot = () => {
        console.log("forgto");
    }

  return (
    <div className={`${styles.container} pt-3`}>
        <Row>
            <div className='pb-5'>
                <h1>INICIO DE SESIÓN</h1>
            </div>
            <div className={`${styles.text_input_container} pb-4`}>
                <label className={styles.text_input_label}>Usuario</label>
                <input value={username} onChange={e => setUsername(e.target.value)} className={styles.text_input}></input>
            </div>
            <div className={`${styles.text_input_container} pb-3`}>
                <label className={styles.text_input_label}>Contraseña</label>
                <input value={password} onChange={e => setPassword(e.target.value)} className={styles.text_input}></input>
            </div>
            <Button onClick={login} className={`${styles.button_green} mb-2`}>Ingresar</Button>
            <Button onClick={forgot} className={`${styles.button_forgot}`}>¿Olvidaste tu contraseña?</Button>
        </Row>
    </div>
  )
}
