import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function NewProducer() {
  const [name, setName] = useState("");
  const [trucks, setTrucks] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(0);
  const [producerTrucks, setProducerTrucks] = useState([]);
  const [displayTruckNames, setDisplayTruckNames] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get('camion_select/').then((response) => {
      setTrucks(response.data);
    })
  }, []);

  const addTruck = () => {
    if(selectedTruck != 0) {
      if(!producerTrucks.includes(selectedTruck)) {
        const newTrucks = [...producerTrucks, selectedTruck];
        setProducerTrucks(newTrucks);
        const result = trucks.find(obj => obj.id == selectedTruck);
        if (result)
        {
          const newTruckNames = [...displayTruckNames, result.identificador]; 
          setDisplayTruckNames(newTruckNames);
        }
        console.log(result)
      }
    }
  }

  const submit = () => {
    axios.post('productor/', {
      nombre: name,
      camiones: producerTrucks
    }).then((response) => {
      setError("")
      window.location.replace('/producer')
    }).catch((err) => {
      setError("Por favor revise todos los campos");
    })
  }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content} pt-5`}>
            <h1>Nuevo productor</h1>

            <div className={`${cruds.inputs}`}>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Nombre de nuevo productor</label>
                    <input className={cruds.text_input} onChange={x => setName(x.target.value)}></input>
                </div>
                <div className={`${cruds.input_container} pb-4`}>
                    <label className={cruds.text_input_label}>Camión</label>
                    <select className={cruds.select_input} onChange={x => setSelectedTruck(x.target.value)}>
                        <option value="">-- Seleccione una opción --</option>
                        {trucks.map((truck, index) => (
                          <option key={index} value={truck.id}>{truck.identificador}</option>
                        ))}
                    </select>
                </div>
                <div className={`${cruds.secondary_button} pb-3`}>
                  <Button onClick={addTruck}>
                      <span>Añadir Camión</span>
                  </Button>
                </div>
                
                <div className={`${cruds.names_box}`}>
                {displayTruckNames.map((truck, index) => (
                  <div key={index}>{truck}</div>
                ))}
                </div>

                <div className={`${cruds.buttons_in_row} pt-3`}>
                  <Link className={`${cruds.save_button}`} to="/producer">
                    <Button>
                      <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                      <span>Regresar</span>
                    </Button>
                  </Link>
                  <div className={`${cruds.save_button}`}>
                    <Button onClick={submit}>
                      <span>Guardar</span>
                    </Button>
                  </div>
                </div>
                <div className={`${cruds.error_span} pt-3`}>
                    <span>{error}</span>
                </div>
            </div>
        </div>
    </div>
  )
}
