import React, { useEffect, useState } from 'react'
import styles from '../styles/Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import axios from '../AxiosInstance'
import Cookies from 'js-cookie';

export default function HeaderComponent() {
  const [username, setUsername] = useState("");
  const sessionId = Cookies.get('listersessionid');

  useEffect(() => {
      axios.get('get_usuario/?listersessionid=' + sessionId).then((response) => {
        setUsername(response.data.nombre + " " + response.data.apellido);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={`${styles.header}`}>
        <div className='ml-3'>
            <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
        </div>
        <div>
            <FontAwesomeIcon icon={faUser} size="2x"/>
            <div className='pb-3'>{username}</div>
        </div>
    </div>
  )
}
