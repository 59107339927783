import axios from "axios";

const instance = axios.create({
    baseURL: "https://lister.arrozchispa.com/api/"
});

const token = "7e69a6ddb7bbd62942995ab01cc964f3bce94fa6";

instance.defaults.headers.common["Authorization"] = token;

export default instance;
//http://localhost:8000/api/
//https://lister.arrozchispa.com/api/