import React, { useEffect, useState } from 'react'
import styles from '../styles/Home.module.css'
import HeaderComponent from '../components/HeaderComponent'
import SidebarComponent from '../components/SidebarComponent'
import axios from '../AxiosInstance'
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent'

export default function HomeScreen() {
    const [username, setUsername] = useState("");
    const sessionId = Cookies.get('listersessionid');
    const [loaded, setLoaded] = useState(false);
    const [tanquesTotal , setTanquesTotal] = useState(0);
    const [silosTotal, setSilosTotal] = useState(0);

    useEffect(() => {
        axios.get(`get_usuario/?listersessionid=${sessionId}`).then((response) => {
            setUsername(response.data.nombre + " " + response.data.apellido);
            setLoaded(true);
        })
        .catch((err) => {
            console.log(err);
        });

        axios.get('dashboard/').then((response) => {
            setTanquesTotal(response.data.tanques_total);
            setSilosTotal(response.data.silos_total);
        })
    }, []);

    const excel = (time) => {
        axios.get(`excel/?timestamp=${time}`, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            document.body.appendChild(link);

            // Simulate a click event to trigger the download
            link.click();

            // Clean up the temporary link and URL object
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    return (
        <div style={{height: '100%'}}>
            <HeaderComponent/>
            <SidebarComponent/>
            {!loaded ?
                <LoadingComponent/>
                :
                <div className={`${styles.content} pt-5`}>
                    <h1>Bienvenido, <span className={`${styles.bold}`}>{username}</span></h1>
                    <div className={'h-100 d-flex flex-column justify-content-between'} style={{height: '100vh'}}>
                        <div className={'d-flex justify-content-center'} style={{
                            color: '#000',
                            fontSize: '24px'
                        }}>
                            <div className={'me-5'}>
                                <div style={{fontWeight: '600'}}>Total Kilogramos Tanques</div>
                                <div>{tanquesTotal.toLocaleString()}Kg</div>
                            </div>

                            <div>
                                <div style={{fontWeight: '600'}}>Total Kilogramos Silos</div>
                                <div>{silosTotal.toLocaleString()}Kg</div>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-center'}>
                            <div className={`${styles.button} me-5`} onClick={() => excel('30d')}>Exportar data 30 días</div>
                            <div className={styles.button} onClick={() => excel('1y')}>Exportar data 1 año</div>
                        </div>
                    </div>
                </div>
            }
        </div>
  )
}
