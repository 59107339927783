import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import axios from '../../AxiosInstance'
import cruds from '../../styles/CRUDS.module.css'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function ArrivalEmpty() {
    const [results, setResults] = useState([]);
    const [reception, setReception] = useState(undefined);

    useEffect(() => {
        axios.get('recepcion_select/?vaciada=false').then((response) => {
            setResults(response.data);
        });
    }, []);

    const submit = () => {
        const result = results.find(obj => obj.id === reception);
        axios.put('recepcion/' + result.id + "/", {
            vaciada: true,
            productor_id: result.productor.id,
            camion_id: result.camion.id
        }).then((response) => {
            window.location.replace('/arrival');
        });
    }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>

        <div className={`${cruds.content} pt-5`}>

            <div className={`${cruds.inputs}`}>
                <h1>Vaciar recepción</h1>

                <div className={`${cruds.input_container} pb-4 mt-4`}>
                    <label className={cruds.text_input_label}>Recepciones</label>
                    <select onChange={x => setReception(x.target.value)} className={cruds.select_input}>
                        <option value="">-- Seleccione una opción --</option>
                        {results.map((result, index) => (
                            <option key={index} value={result.id}>{result.productor.nombre}/{result.camion.identificador}</option>
                            ))}
                    </select>
                </div>

                <div className={`${cruds.buttons_in_row} pt-3`}>
                    <Link className={`${cruds.save_button}`} to="/arrival">
                        <Button>
                        <FontAwesomeIcon icon={faChevronLeft} color="black"/>
                        <span>Regresar</span>
                        </Button>
                    </Link>
                    <div className={`${cruds.save_button}`}>
                        <Button onClick={submit}>
                            <span>Guardar</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
