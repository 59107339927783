import React, { useEffect, useState } from 'react'
import HeaderComponent from '../../components/HeaderComponent'
import SidebarComponent from '../../components/SidebarComponent'
import cruds from '../../styles/CRUDS.module.css'
import axios from '../../AxiosInstance'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'

export default function FillTank() {
    const [producers, setProducers] = useState([]);
    const [truckList, setTruckList] = useState([]);
    const [tanks, setTanks] = useState([]);

    const [selectionTanks, setSelectionTanks] = useState([]);
    const [listers, setListers] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [error, setError] = useState("");

    const [fillList, setFillList] = useState([]);

    const [form, setForm] = useState({});
    const setField = (field, value) => {
        setForm({
        ...form, 
        [field]:value
        });
    };

    useEffect(() => {
        axios.get('get_productoresrecepcion/').then((response) => {
            setProducers(response.data.productores);
            setTruckList(response.data.camiones);
        });

        axios.get('tanque_select/?kg_estimados=18000').then((response) => {
            setTanks(response.data);
        });

        axios.get('lister/').then((response) => {
            setListers(response.data.results);
        });
    }, []);

    const getTrucks = (event) => {
        setForm({
            ...form,
            productor_id: event.target.value,
            camion_id: null,
        });
        setTrucks([]);
        const result = truckList.filter(obj => obj.productor_id === event.target.value);

        setTrucks(result)
    };

    const getTanks = (event) => {
        setForm({
            ...form,
            lister_id: event.target.value,
            tanque_id: null,
        })

        setSelectionTanks([]);
        const result = tanks.filter(obj => obj.lister.id === event.target.value);
        setSelectionTanks(result);
    }

    const selectTank = (event) => {
        const result = tanks.filter(obj => obj.id === event.target.value);
        console.log(result[0].comentario);
        setForm({
            ...form,
            tanque_id:  event.target.value,
            comentario: result[0].comentario
        });
    }

    const submit = () => {
        // Check if new amount doesn't excede hardcoded 18k limit
        const result = tanks.filter(obj => obj.id === form.tanque_id);
        const newTotal = parseInt(result[0].kg_estimados) + parseInt(form.kg_estimados)

        if(newTotal <= 18000)
        {
            axios.post('llenado/', {
                fecha_hora: form.fecha_hora,
                humedad_recepcion: form.humedad_recepcion,
                temperatura_recepcion: form.temperatura_recepcion,
                tanque_id: form.tanque_id,
                productor_id: form.productor_id,
                camion_id: form.camion_id,
                kg_estimados: form.kg_estimados,
                comentario: form.comentario
            }).then((response) => {
                setError("")
                window.location.replace('/tank')
            }).catch((err) => {
                setError("Por favor revise todos los campos");
            });
        }
        else
        {
            setError("El nuevo valor de KG Estimados excede el máximo de 18000");
        }
    }

  return (
    <div>
        <HeaderComponent/>
        <SidebarComponent/>
        <div className={`${cruds.content}pt-5`}>
            <div className={'mt-4'}>
                <h1>Llenar tanque</h1>
            </div>
                    <div className={`${cruds.inputs}`}>

                        <div className={`${cruds.input_container} pb-4 mt-4`}>
                            <label className={cruds.text_input_label}>Fecha y Hora Recepción</label>
                            <input type="datetime-local" className={cruds.text_input} onChange={x => setField("fecha_hora", x.target.value)}></input>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Productor</label>
                            <select onChange={getTrucks} className={cruds.select_input}>
                                <option value="">-- Seleccione una opción --</option>
                                {producers.map((productor, index) => (
                                    <option key={index} value={productor.id}>{productor.nombre}</option>
                                    ))}
                            </select>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Camión</label>
                            <select className={cruds.select_input} onChange={x => setField("camion_id", x.target.value)}>
                                <option value="">-- Seleccione una opción --</option>
                                {trucks.map((truck, index) => (
                                    <option key={index} value={truck.id}>{truck.identificador}</option>
                                    ))}
                            </select>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Lister</label>
                            <select onChange={getTanks} className={cruds.select_input}>
                                <option value="">-- Seleccione una opción --</option>
                                {listers.map((lister, index) => (
                                    <option key={index} value={lister.id}>{lister.nombre}</option>
                                    ))}
                            </select>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Tanque</label>
                            <select onChange={selectTank} className={cruds.select_input}>
                                <option value="">-- Seleccione una opción --</option>
                                {selectionTanks.map((tank, index) => (
                                    <option key={index} value={tank.id}>{tank.nombre}</option>
                                    ))}
                            </select>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Humedad</label>
                            <input type="number" className={cruds.text_input} onChange={x => setField("humedad_recepcion", x.target.value)}></input>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Temperatura</label>
                            <input type="number" className={cruds.text_input} onChange={x => setField("temperatura_recepcion", x.target.value)}></input>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>KG Estimados</label>
                            <input type="number" className={cruds.text_input} onChange={x => setField("kg_estimados", x.target.value)}></input>
                        </div>

                        <div className={`${cruds.input_container} pb-4`}>
                            <label className={cruds.text_input_label}>Comentario</label>
                            <input className={cruds.text_input} onChange={x => setField("comentario", x.target.value)} value={form.comentario}></input>
                        </div>
                    </div>

                    <div className={`${cruds.oneToManyBox} pb-4 mt-4`}>
                        {fillList.map((item, index) => (
                            <div key={index} className={cruds.boxItem}>
                                <span>{item.producer.nombre} {item.truck.identificador} -> </span>
                            </div>
                        ))}
                    </div>

            <div className={`${cruds.buttons_in_row}`}>
                <Link className={`${cruds.save_button}`} to="/tank">
                    <Button>
                        <FontAwesomeIcon icon={faChevronLeft} color="black" className={'pe-2'}/>
                        <span>Regresar</span>
                    </Button>
                </Link>
                <div className={`${cruds.save_button}`}>
                    <Button onClick={submit}>
                        <span>Guardar</span>
                    </Button>
                </div>

                <Link className={`${cruds.save_button}`} to="/tank/fill/history">
                    <Button>
                        <span>Historial</span>
                    </Button>
                </Link>
            </div>

            <div className={`${cruds.error_span} pt-3`}>
                <span>{error}</span>
            </div>
        </div>
    </div>
  )
}
